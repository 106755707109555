import React, { FC, useState } from "react";
import "../Styles/Form.css";
import { PostData } from "../utils/API_calls";
import Button from "./Button";
import { PopupActions, DialogType } from "react-custom-popup";
import Input from "./Input";
import { Navigate } from "react-router-dom";
import { FORM_URL, SiteKey } from "../utils/const";
import { styles } from "./ErrorStyle/errorMessage";
import { IsValidEmail } from "../utils/EmailsProvider";
import ReCAPTCHA from "react-google-recaptcha";
import CapatchaBtn from "../Components/CapatchaBtn";
import { useNavigate } from "react-router-dom";
interface dataInput {
  name: string;
  lastName: string;
  function: string;
  company: string;
  email: string;
  telephone: string;
}

const Form: FC = () => {
  let navigate = useNavigate();
  const data: { [key: string]: string } = {
    name: "",
    lastName: "",
    fonction: "",
    company: "",
    email: "",
    telephone: "",
  };
  const [user, setUser] = useState<dataInput | any>(data);
  const [errors, setErrors] = useState<string[] | any>([]);
  const OnChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(user);
  };
  const HandleSubmit = async () => {
    const PropretyUser = Object.keys(user);
    let Errors: any = [];
    for (var i = 0; i < PropretyUser.length; i++) {
      console.log(user[PropretyUser[i]]);
      if (user[PropretyUser[i]].length === 0) {
        Errors.push(PropretyUser[i]);
      }
    }
    setErrors(Errors);
    let promises = new Promise(async (resolve, reject) => {
      let isValidEmail = await IsValidEmail(user.email);
      resolve(isValidEmail);
    });
    promises.then(async (result) => {
      if (!result) {
        Errors.push("email");
        setErrors(Errors);
        PopupActions.showToast({
          text: "Merci de saisir votre adresse E-mail professionnelle !",
          type: DialogType.WARNING,
          containerStyle: styles.container,
        });
      }
      if (Errors.length === 0 && result) {
        await PostData("user", user)
          .then((res) => {
            console.log(res.data);
            // /diagnostics/diagnosticsfrom
            window.location.replace(FORM_URL + "?user_id=" + res.data.data.id);
          })
          .catch((err) => {
            PopupActions.showToast({
              text: "Server Error",
              type: DialogType.WARNING,
              containerStyle: styles.container,
            });
          });
      }
    });
  };
  const ValidateInput = () => {
    if (
      user.name.length === 0 ||
      user.lastName.length === 0 ||
      user.fonction.length === 0 ||
      user.company.length === 0 ||
      user.email.length === 0 ||
      user.telephone.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isValid = ValidateInput();
  const [recapatchaValue, setRecapatchaValue] = useState("");
  function onChange(value: any) {
    setRecapatchaValue(value);
    console.log(recapatchaValue);
  }
  return (
    <div className="container">
      <div id="first_container" className="form_main_container">
        <div className="first_step">
          <h1 className="form_title">Démarrez votre diagnostic maintenant</h1>
          <Button
            title={"Démarrer"}
            onTop={false}  action={() => {
              window.open("https://form.sastec.dev","_self");
            }} 
            // action={() => {
            //   let firstElement = document.getElementById("first_container") || {
            //     style: { display: "" },
            //   };
            //    firstElement.style.display = "none";
              // let SecondElement = document.getElementById("second_step") || {
              //   style: { display: "" },
              // };
              // SecondElement.style.display = "block";
            // }}
          />
        </div>
      </div>
      <div id="second_step" className="form_second">
        <h1 className="form_title">
          Merci de remplir ces champs avant de démarrer votre diagnostic
        </h1>
        <div className="container_form_margin">
          <div className="two_input_container">
            <Input
              isNum={false}
              hasError={errors.indexOf("name") > -1 ? true : false}
              title="Prénom*"
              name="name"
              handleChange={OnChange}
              value={user.name}
              placeholder={"Prénom*"}
            />
            <Input
              isNum={false}
              hasError={errors.indexOf("lastName") > -1 ? true : false}
              title="Nom* "
              name="lastName"
              handleChange={OnChange}
              value={user.lastName}
              placeholder={"Nom*"}
            />
          </div>
        </div>
        <div className="container_form_margin margin_top">
          <div className="two_input_container">
            <Input
              isNum={false}
              hasError={errors.indexOf("fonction") > -1 ? true : false}
              title="Fonction*"
              name="fonction"
              handleChange={OnChange}
              value={user.fonction}
              placeholder={"Fonction*"}
            />
            <Input
              isNum={false}
              hasError={errors.indexOf("company") > -1 ? true : false}
              title="Nom de la société*"
              name="company"
              handleChange={OnChange}
              value={user.company}
              placeholder={"Nom de la société*"}
            />
          </div>
        </div>
        <div className="container_form_margin margin_top">
          <div className="two_input_container">
            <Input
              isNum={false}
              hasError={errors.indexOf("email") > -1 ? true : false}
              title="E-mail*"
              name="email"
              handleChange={OnChange}
              value={user.email}
              placeholder={"E-mail*"}
            />
            <Input
              isNum={true}
              hasError={errors.indexOf("telephone") > -1 ? true : false}
              title="Numéro de téléphone*"
              name="telephone"
              handleChange={OnChange}
              value={user.telephone}
              placeholder={"Numéro de téléphone*"}
            />
          </div>
        </div>
        <div></div>
        <div className="container_form_margin button_container">
          <div className="button_capatcha">
            <form>
              <ReCAPTCHA
                sitekey={SiteKey || "6Lc9ApogAAAAAEOAjfI_ZvQ7zpvHgbEqAy5s2O36"}
                onChange={onChange}
              />
            </form>
            <CapatchaBtn
              title={"Commencer"}
              capatchaPassed={recapatchaValue && isValid ? true : false}
              action={() => {
                HandleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
