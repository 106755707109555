export const startUpDescription = `Vous commercialisez une plateforme web ou une application mobile et
vous avez comme de nombreux entrepreneurs “le nez dans le guidon”.
Une expertise technique avec un regard extérieur apporte souvent “un
vent de fraîcheur”. Cet outil est un compagnon idéal pour prendre du
recul, arbitrer sur des choix techniques stratégiques et rassurer
vos partenaires.`;

export const Accelrator = `Vous accompagnez de nombreuses startups du digital pour maximiser
leur chance de succès. Vous ne disposez pas d’un outil complet et
simple d’utilisation pour vous permettre d’évaluer la pertinence
technique d’une solution digitale lorsque des
partenaires/investisseurs doivent financer le développement de leurs
activités. En tant que tiers de confiance et garant de la qualité
des produits, vous cherchez toujours à “de-risquer” un maximum les
futurs investissements . Sastec Diagnostic sera pour votre
organisation un précieux outil d'aide à la décision.`;

export const FORM_URL = window.env.FROM_URL;

export const SiteKey = window.env.SITE_KEY;


export const SITE_IP = window.env.SITE_IP