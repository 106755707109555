import React, { FC } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../Styles/LandingPageList.css";
import { styles } from "./animation/animation";

const Listdata = [
  { num: 1, phrase: "Infrastructure applicative et architecture logicielle" },
  { num: 2, phrase: "Hébergement, outils DeVops et mise en production" },
  { num: 3, phrase: "Aspect mobile, E-commerce" },
  { num: 4, phrase: "Methodologie de développement" },
  { num: 5, phrase: "Bases de données" },
  { num: 6, phrase: "Qualité du code de développement" },
  { num: 7, phrase: "Outils, langages et frameworks utilisés" },
  { num: 8, phrase: "UI/UX Design" },
  { num: 9, phrase: "Sécurité & SEO" },
  { num: 10, phrase: "Accessibilité & Test" },
];
const Laptop = require("../StaticAssets/laptop.png");

const AboutUs: FC = () => {
  return (
    <div className="container landingPage_outter_container">
      <AnimationOnScroll initiallyVisible={true} animateIn="animate__jackInTheBox">
      <img
        className=" header-hero-image text-center wow fadeIn landing_page_laptop"
        data-wow-duration="1.3s"
        data-wow-delay="1.4s"
        style={styles.laptopStyle}
        src={Laptop}
        alt=""
      />
      </AnimationOnScroll>
      <h1
        className=" single-services text-center mt-30 wow fadeIn intro_about_us"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
        style={styles.aboutUsTextStyle}
      >
        Une interface web, simple d’utilisation, qui passe en revue les
        principaux aspects techniques et verrous technologiques ainsi que la
        méthodologie de développement.
      </h1>
      <div
        className="list_container single-services text-center mt-30 wow fadeIn "
        data-wow-duration="1s"
        data-wow-delay="0.2s"
        style={styles.aboutUsListStyle}
      >
        {Listdata.map((item) => {
          return (
            <div className="item_list" key={item.num}>
              <h1 className="list_num">{item.num}.</h1>
              <h1 className="text_under_numbers">{item.phrase}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutUs;
