/*  All animation styles are included in this file 
    LIB : https://github.com/animate-css/animate.css
*/

export const styles: { [key: string]: React.CSSProperties } = {
  /* about us list component : */

  laptopStyle: {
    visibility: "visible",
    animationDuration: "1.3s",
    animationDelay: "0s",
    animationName: "jackInTheBox",
  },
  aboutUsTextStyle: {
    visibility: "visible",
    animationDelay: "0.2s",
    animationDuration: "1s",
    animationName: "fadeIn",
  },
  aboutUsListStyle: {
    visibility: "visible",
    animationDelay: "0.2s",
    animationDuration: "1s",
    animationName: "fadeIn",
  },

  /* startHere component : */

  startHereTitleStyle: {
    visibility: "visible",
    animationDelay: "0s",
    animationName: "fadeInUp",
    animationDuration: "1.3s",
  },
  startHereTextStyle: {
    visibility: "visible",
    animationDelay: "0s",
    animationName: "fadeInUp",
    animationDuration: "1.3s",
  },
  startHereButtonStyle: {
    visibility: "visible",
    animationDelay: "0s",
    animationName: "fadeInUp",
    animationDuration: "1.3s",
  },

  /* ExempleClient Component */

  fadeInLeft: {
    visibility: "visible",
    animationDuration: "2s",
    animationDelay: "0s",
    animationName: "fadeInLeftBig",
  },
  fadeInRight: {
    visibility: "visible",
    animationDuration: "2s",
    animationDelay: "0s",
    animationName: "fadeInRightBig",
  },
};
