import axios from "axios";

let API = window.env.API_HOST + "/api/v1/";

export const PostData = async (path, data) => {
  return axios.post(`${API}${path}`, data, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("user_token"),
    },
  });
};
export const GetData = async (path, data) => {
  return axios.get(`${API}${path}`, data);
};
