import React, { useEffect } from "react";
import "../Styles/LandingPage.css";
import AboutUs from "../Components/AboutUs";
import Footer from "../Components/Footer";
import Contact from "../Components/Contact";
import StartHere from "../Components/StartHere";
import ExempleClient from "../Components/ExempleClient";
import Form from "../Components/Form";
const Logo = require("./../StaticAssets/scrolToTop.png");
const Flesh = require("./../StaticAssets/bottom.png");
const LandingPage: React.FC = () => {
  function scrollFunction() {
    var mybutton = document.getElementById("myBtn") || {
      style: { display: "" },
    };
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <div className="landingPage_Background_container">
      <StartHere
        startHere={() => {
          let firstElement = document.getElementById("first_container") || {
            style: { display: "" },
          };
          firstElement.style.display = "none";
          let SecondElement = document.getElementById("second_step") || {
            style: { display: "" },
            offsetLeft: 0,
            offsetTop: 0,
          };
          SecondElement.style.display = "block";
          window.scrollTo(SecondElement.offsetLeft, SecondElement.offsetTop);
        }}
      />
      <div className="container_scondary">
        <AboutUs />
        <ExempleClient />
        <Form />
        <Contact />
        <Footer />
      </div>
      <div id="myBtn" className="Scroll_top" title="Go to top">
        <div onClick={topFunction} className="WhiteBox">
          <img className="flesh_bottom" src={Flesh} alt="" />
        </div>
        <img className="Top_Button" src={Logo} alt="" />
      </div>
    </div>
  );
};

export default LandingPage;
