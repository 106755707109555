import React from "react";
import "../Styles/Button.css";
interface ButtonCapatchaProps {
  title: string;
  action: () => void;
  capatchaPassed: boolean;
}

const CapatchaBtn: React.FC<ButtonCapatchaProps> = ({
  title,
  action,
  capatchaPassed,
}) => {
  return (
    <button
      className={capatchaPassed ? "capatcha_off" : "capatcha_on"}
      onClick={action}
    >
      {title}
    </button>
  );
};

export default CapatchaBtn;
