import React, { FC } from "react";
import "../../Styles/DevFactoryContent.css";
import { Link, To } from "react-router-dom";
import Button from "../Button";
const line = require("../../StaticAssets/Rectangle 716.png");
const DevFacLogo = require("../../StaticAssets/Dev factory logo 1.png");
const DevFactoryContent: FC = () => {
  return (
    <div className="devFac_container">
      <img className="devFac_line" src={line} alt="" />
      <h1 className="devFac_title">
        Avec DevFactory.ai, réduisez de 30% les temps de développement web
        mobile !
      </h1>
      <div className="devFac_content">
        <div className="devFac_p">
          <p className="underlineText">
            Bénéficiez de nos différents services :{" "}
          </p>
          <ul className="list_item">
            <li className="item_list_cta">
              Le générateur de Front qui permet de créer des pages Figma
              automatiquement et de convertir le design en code
            </li>
            <li className="item_list_cta">
              Le générateur
              d’API qui permet à partir d’un modèle de données de générer
              automatiquement des codes
            </li>
            <li className="item_list_cta">
              L’IDE Cloud qui permet de créer un
              environnement de développement automatisé et d’en faciliter
              l’exécution.
            </li>
          </ul>
        </div>
        <img className="devFac_logo" src={DevFacLogo} alt="" />
      </div>
        <div className="extra_content">
          <p className="dev_content">Découvrez son fonctionnement et ses avantages !</p>
          <button onClick={()=> {window.location.replace("https://www.devfactory.ai/")}} className="devFac_button">
          Cliquer-ici
          </button>

      </div>
    
        <Link  className="retour_a_accueil" to={"/"}>
        Retour à l'accueil
        </Link>
      
    </div>
  );
};

export default DevFactoryContent;
