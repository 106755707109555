import React, { FC } from "react";
import Button from "./Button";
import "animate.css";
import "../Styles/StartHere.css";
import { styles } from "./animation/animation";
const sasteclogo = require("../StaticAssets/logo_sastec_white.png");
interface StartHereProps {
  startHere: () => void;
}

const StartHere: FC<StartHereProps> = ({ startHere }) => {
  
  return (
    <div className="landingPage_intro ">
      <div className="container">
        <img className="sastec_logo" src={sasteclogo} alt="" />
        <div className="container_inside">
          <h1
            className=" text wow fadeInUp landing_page_title"
            data-wow-duration="1.3s"
            data-wow-delay="0s"
            style={styles.startHereTitleStyle}
          >
            Sastec Diagnostic
          </h1>
          <p
            className=" text wow fadeInUp landingPage_firstParagraph"
            data-wow-duration="1.3s"
            data-wow-delay="0s"
            style={styles.startHereTextStyle}
          >
            Un outil d’aide à la décision qui permet de réaliser de manière
            exhaustive l'audit technique d’une plateforme web et/ou d’une
            application mobile.
          </p>
          <div
            className=" text wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0s"
            style={styles.startHereButtonStyle}
          >
            <Button title="Démarrer"      onTop={false}  action={() => {
      window.open("https://form.sastec.dev","_self");
    }}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartHere;
