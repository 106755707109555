import React from "react";
import LandingPage from "./Pages/LandingPage";
import CallToActionPage from "./Pages/CallToActionPage";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "animate.css/animate.min.css";
import IframePage from "./Pages/IframePage";
function App() {
  return (
    <Routes >
      <Route path="/" element={<LandingPage />} />
      <Route path="/rendez-vous" element={<CallToActionPage />} />
      <Route element={<LandingPage />} />
    </Routes>
  );
}

export default App;
