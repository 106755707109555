import React from "react";
import "../Styles/Button.css";
interface ButtonProps {
  title: string;
  action: () => void;
  onTop: boolean;
}

const Button: React.FC<ButtonProps> = ({ title, action, onTop }) => {
  return (
    <button className={onTop ? "onTop cursor" : "onBottom cursor"} onClick={action}>
      {title}
    </button>
  );
};

export default Button;
