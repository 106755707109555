import React, { FC } from "react";
import "../../Styles/Rdv.css";
import Button from "../Button";
const Rdv: FC = () => {
  return (
    <div>
      <h1 className="title_rdv">Pour aller plus loin </h1>
      <div className="rdv_content">
        <p className="content_text">
          Vous souhaitez discuter d’un projet, en savoir plus sur ce que nous
          pouvons vous apporter, réservez un créneau de 15 mn pour un premier
          échange :
        </p>
        <div className="margin_top_button">
          <Button
            title={"Je prends RDV "}
            onTop={false}
            action={() => {
              window.open(
                "https://sastec.pipedrive.com/scheduler/4jXjolhZ/projets-developpement-web-mobile",
                "_blank"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Rdv;
