import React from "react";
import "../Styles/Input.css";
import TextField from "@mui/material/TextField";

interface InputProps {
  title: string;
  name: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError: boolean;
  isNum: boolean;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  title,
  isNum,
  handleChange,
  value,
  name,
  hasError,
  placeholder,
}) => {
  return (
    <div className="Input_container">
      {/* <p className={hasError ? "Input_title error" : "Input_title"}>{title}</p> */}
      <TextField
        color={hasError ? "secondary" : "primary" }
        id="outlined-basic"
        variant="outlined"
        label={placeholder}
        type={isNum ? "number" : "text"}
        onChange={handleChange}
        value={value}
        name={name}
        sx={{ input: { color: "white" } }}
      />
    </div>
  );
};

export default Input;
