import React, { FC } from "react";
import "../Styles/ExempleClient.css";
import { startUpDescription, Accelrator } from "../utils/const";
import { styles } from "./animation/animation";
import { AnimationOnScroll } from 'react-animation-on-scroll';
const startUpLogo = require("../StaticAssets/start up.png");
const accelerateurLogo = require("../StaticAssets/un accélérateur 2.png");
const line = require("../StaticAssets/Rectangle 716.png");
const ExempleClient: FC = () => {
  return (
    <div className="exempleClient_container container">
      <h1 className="exempleClient_title">
        Sastec Diagnostic est fait pour vous si vous êtes :
      </h1>
      <AnimationOnScroll initiallyVisible={true} animateIn="animate__fadeInUp" >
      <div
        className=" about-content mt-50 wow fadeInLeftBig exempleClient_startup"
        data-wow-duration="1s"
        data-wow-delay="0s"
      >
        <div className="startup">
          <img className="upper_line" src={line} alt="" />
          <h1 className="startup_title">Une Startup </h1>
          <p className="startup_p">{startUpDescription}</p>
        </div>
        <div className="startup_logo_container">
          <img className="startup_logo" src={startUpLogo} alt="" />
        </div>
      </div>
      </AnimationOnScroll>
     
      {/* -----------------------------*------------------------------- */}
      <AnimationOnScroll  animateIn="animate__fadeInUp" >

      <div
        className=" about-content mt-50 wow fadeInLeftBig exempleClient_startup second_container"
        data-wow-duration="1s"
        data-wow-delay="0s"
      >
        <div className="startup_logo_container Logo_Image ">
          <img className="startup_logo" src={accelerateurLogo} alt="" />
        </div>
        <div className="startup ContainerStart">
          <img className="upper_line" src={line} alt="" />
          <h1 className="startup_title">Un Accélérateur</h1>
          <p className="startup_p">{Accelrator}</p>
        </div>
      </div>
      </AnimationOnScroll>
    </div>
  );
};

export default ExempleClient;
