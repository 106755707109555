import React from "react";
import "../Styles/Footer.css";
const Footer: React.FC = () => {
  return (
    <div className="footer_container">
      
      <p className="footer_content" >Copyright © {new Date().getFullYear()} all rights reserved Sastec Group</p>
    </div>
  );
};

export default Footer;
