import freeEmails from "./emails.txt"
const RegexEmailValidator =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const IsValidEmail = async (email) => {
    let IsValid = String(email).toLocaleLowerCase().match(RegexEmailValidator)
    if (!IsValid) {
        return false
    }
   return  await fetch(freeEmails)
        .then(r => r.text())
        .then(text => {
            let PublicDomains = text.split(/\r?\n/)
            let domain = email.split("@")[1]
            console.log(domain)
            for (var i = 0; i < PublicDomains.length; i++) {
                console.log('this is the public email test =>' , PublicDomains[i] , PublicDomains[i] == domain.toLocaleLowerCase())
                console.log('this is the public email condition =>' , PublicDomains[i] == domain.toLocaleLowerCase())

                if (PublicDomains[i] == domain.toLocaleLowerCase()) {
                    return false
                }
            }
            return true
        });
    return true
}