import React from "react";
import "../Styles/CallToActionPage.css";
import Rdv from "../Components/callToactionComponents/Rdv";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import DevFactoryContent from "../Components/callToactionComponents/DevFactoryContent";
const sastecLogo = require("../StaticAssets/Logo sastec group 2.png");
const CallToActionPage: React.FC = () => {
  return (
    <div className="CallToActionPage_container ">
      <div className="container">
        <img src={sastecLogo} className="logo" alt="" />
        <div className="container_second">
          <Rdv />
          <DevFactoryContent />
        </div>
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default CallToActionPage;
